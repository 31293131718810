
import { HttpClient } from './http-client';
import { Cart } from './Cart';
import { ErpCalendar } from './ErpCalendar';
import { SecuritySchemes } from './SecuritySchemes';
import { ErpOrderHistory } from './ErpOrderHistory';
import { ErpOrderDetail } from './ErpOrderDetail';
import { StockBalance } from './StockBalance';
import { Atp } from './Atp';
import { OrderAtp } from './OrderAtp';
import { Invoice } from './Invoice';
import { PlaceOrder } from './PlaceOrder';
import { Payment } from './Payment';
import { Price } from './Price';
import { Project } from './Project';
import { Series } from './Series';
import { Xitem } from './Xitem';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/wcs/resources':'/api/resources',
	isPublic: true
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.TRANSACTION_ORIGIN as string) + '/wcs/resources',
});

export const customCart = (pub: boolean) => new Cart(pub ? publicClient : privateClient);
export const customErpCalendar = (pub: boolean) => new ErpCalendar(pub ? publicClient : privateClient);
export const customSecuritySchemes = (pub: boolean) => new SecuritySchemes(pub ? publicClient : privateClient);
export const customErpOrderHistory = (pub: boolean) => new ErpOrderHistory(pub ? publicClient : privateClient);
export const customErpOrderDetail = (pub: boolean) => new ErpOrderDetail(pub ? publicClient : privateClient);
export const customStockBalance = (pub: boolean) => new StockBalance(pub ? publicClient : privateClient);
export const customAtp = (pub: boolean) => new Atp(pub ? publicClient : privateClient);
export const customOrderAtp = (pub: boolean) => new OrderAtp(pub ? publicClient : privateClient);
export const customInvoice = (pub: boolean) => new Invoice(pub ? publicClient : privateClient);
export const customPlaceOrder = (pub: boolean) => new PlaceOrder(pub ? publicClient : privateClient);
export const customPayment = (pub: boolean) => new Payment(pub ? publicClient : privateClient);
export const customPrice = (pub: boolean) => new Price(pub ? publicClient : privateClient);
export const customProject = (pub: boolean) => new Project(pub ? publicClient : privateClient);
export const customSeries = (pub: boolean) => new Series(pub ? publicClient : privateClient);
export const customXitem = (pub: boolean) => new Xitem(pub ? publicClient : privateClient);

import { CartCart } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Cart<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description Gets order details for the shopping cart.
	 *
	 * @tags Cart
	 * @name CartGetCart
	 * @summary Get order details
	 * @request GET:/store/{storeId}/cart/@self
	 * @secure
	 * @response `200` `CartCart` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	cartGetCart = (
		storeId: string,
		query?: {
			/**
			 * Language identifier. If not specified, the locale parameter will be used. If locale isn't specified, then the store default language shall be used.
			 * @example -1, -2, -3, -4, -5,-6, -7, -8, -9, -10
			 */
			langId?: string;
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
			/**
			 * Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
			 * @format int32
			 */
			pageNumber?: number;
			/**
			 * Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
			 * @format int32
			 */
			pageSize?: number;
			/** The currency code to use for example, currency=USD. If no currency code is specified, the store default currency is used. */
			currency?:
				| 'JPY'
				| 'CNY'
				| 'BRL'
				| 'EUR'
				| 'RON'
				| 'RUB'
				| 'PLN'
				| 'USD'
				| 'KRW'
				| 'TWD'
				| 'CAD'
				| 'GBP';
			/** The sort order of the results for example, sort by orderItemID or createDate. */
			sortOrderItemBy?:
				| 'orderItemID'
				| 'createDate'
				| 'quantity'
				| 'inventoryStatus'
				| 'price'
				| 'unitPrice'
				| 'partNumber';
			/** The sort order of the requested field. ASC for ascending order and DESC for descending order. Default is ascending. */
			sortOrder?: 'ASC' | 'DESC';
			/** Profile name. Profiles determine the subset of data returned by a query. */
			profileName?: 'IBM_Summary' | 'IBM_Details';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('cartGetCart'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'cartGetCart',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<CartCart, void>({
			path: `/store/${storeId}/cart/@self`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * @description Deletes all order items from the shopping cart.
	 *
	 * @tags Cart
	 * @name CartCancelOrderInCart
	 * @summary Delete items
	 * @request DELETE:/store/{storeId}/cart/@self
	 * @secure
	 * @response `204` `void` The requested completed successfully. No content is returned in the response.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	cartCancelOrderInCart = (
		storeId: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('cartCancelOrderInCart'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'cartCancelOrderInCart',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<void, void>({
			path: `/store/${storeId}/cart/@self`,
			method: 'DELETE',
			query: query,
			secure: true,
			storeId,
			...params,
		});
	};
}

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Settings } from '@/data/Settings';
import { PageDataFromId } from '@/data/types/PageDataFromId';
import { switchOnMock } from '@/data/utils/switchOnMock';
import {
	DEFAULT_CURRENCY,
	DEFAULT_ERROR_META as DEFAULT_ERROR_META_CORE,
	DEFAULT_LANGUAGE,
	DEFAULT_LAYOUT,
	DEFAULT_LOCALE,
	DEFAULT_META as DEFAULT_META_CORE,
	DEFAULT_PAGE_DATA as DEFAULT_PAGE_DATA_CORE,
	HEADLESS_STORE_STORE_CONTEXT_ROOT,
	INITIAL_SETTINGS_BASE,
	mockContext,
} from './../../core/config/DEFAULTS';

export {
	DEFAULT_CURRENCY,
	DEFAULT_LANGUAGE,
	DEFAULT_LAYOUT,
	DEFAULT_LOCALE,
	HEADLESS_STORE_STORE_CONTEXT_ROOT,
};
export const DEFAULT_META = {
	...DEFAULT_META_CORE,
	title: 'Kakel',
	description: 'Kakel',
	keywords: 'kakel',
};
export const DEFAULT_ERROR_META = {
	...DEFAULT_ERROR_META_CORE,
	title: 'Ett fel uppstod',
	description: 'Generic Error',
};
export const DEFAULT_PAGE_DATA: PageDataFromId = {
	...DEFAULT_PAGE_DATA_CORE,
	page: {
		...DEFAULT_PAGE_DATA_CORE.page,
		title: 'Sidan kunde inte hittas',
		metaDescription: 'Sidan kunde inte hittas',
	},
};

const INITIAL_SETTINGS_EXTENDED = {
	...INITIAL_SETTINGS_BASE,
	filterCurrency: 'SEK',
};

export const INITIAL_SETTINGS = switchOnMock<Settings>({
	value: INITIAL_SETTINGS_EXTENDED,
	mockValue: { ...INITIAL_SETTINGS_EXTENDED, state: 'open', context: mockContext, error: false },
});

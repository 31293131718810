import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SecuritySchemes<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @name BasicAuthSecuritySchemes
	 * @request BASIC AUTH:securitySchemes
	 * @secure
	 */
	basicAuthSecuritySchemes = (params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('basicAuthSecuritySchemes'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: null ?? {},
				methodName: 'basicAuthSecuritySchemes',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<any, any>({
			path: `securitySchemes`,
			method: 'BASIC AUTH',
			secure: true,
			...params,
		});
	};
}

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const STORE_LOCATOR_LIBRARY: any = ['places'];
export const STORE_LIST_RADIUS = 40;
export const GOOGLE_MAP_LANGUAGE = 'sv';
export const GOOGLE_MAP_REGION = 'SE';

export const GOOGLE_MAP_ZOOM = {
	INIT: 10,
	ZOOM: 14,
};

export const DEFAULT_LOCATION = {
	// Kungliga slottet, 107 70 Stockholm
	lat: 59.326812,
	lng: 18.071688,
};

export const STORE_LOCATOR_STATE_KEY = 'storeLocator';

import {
	ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerCheckoutToken,
	ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerValidatePayment,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Payment<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags Payment
	 * @name GetCheckoutToken
	 * @summary Gets a usable checkout token for the current order.
	 * @request GET:/store/{storeId}/payment/checkout_token
	 * @secure
	 * @response `200` `ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerCheckoutToken` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	getCheckoutToken = (storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('getCheckoutToken'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: null ?? {},
				methodName: 'getCheckoutToken',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerCheckoutToken,
			void
		>({
			path: `/store/${storeId}/payment/checkout_token`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name ValidatePayment
	 * @summary Validates the payment for current order.
	 * @request GET:/store/{storeId}/payment/validate_payment
	 * @secure
	 * @response `200` `ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerValidatePayment` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	validatePayment = (storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('validatePayment'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: null ?? {},
				methodName: 'validatePayment',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComCustomerCommerceRestPaymentHandlerPaymentResourceHandlerValidatePayment,
			void
		>({
			path: `/store/${storeId}/payment/validate_payment`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}

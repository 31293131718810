/**
 * Licensed Materials - Property of FIWE.
 * (C) Copyright FIWE  2023.
 */

import { customProject } from 'integration/generated/custom';
import {
	CustomGetProjectsResult,
	CustomSetProjectResult,
} from 'integration/generated/custom/data-contracts';

/**
 * This REST service method returns price information.
 */
export const DATA_KEY = 'customGetProjects';

export const DATA_KEY_UPDATE = 'customGetProject';

export const fetcher =
	(pub: boolean) =>
	/**
	 * The data fetcher for customer projects
	 * @param query The request query.
	 *
	 * @returns Fetched Projects .
	 */
	async (
		storeId: string,
		query?: { keyword?: string; pageSize?: number; pageNumber?: number; sortOrder?: number }
	): Promise<CustomGetProjectsResult | undefined> => {
		try {
			return await (customProject(pub).projectGetProjects(
				storeId,
				query
			) as Promise<unknown> as Promise<CustomGetProjectsResult>);
		} catch (error: any) {
			console.error(error);
			return undefined;
		}
	};

export const update =
	(pub: boolean) =>
	/**
	 * The data fetcher for customer projects
	 * @param query The request query.
	 *
	 * @returns Fetched Projects .
	 */
	async (storeId: string, projectId: string): Promise<CustomSetProjectResult | undefined> => {
		try {
			return await (customProject(pub).projectSetProject(
				storeId,
				projectId
			) as Promise<unknown> as Promise<CustomSetProjectResult>);
		} catch (error: any) {
			console.error(error);
			return undefined;
		}
	};

/*
 *	Context for reading active Jeeves project from backend
 */
import { useNextRouter } from '@/data/Content/_NextRouter';
import { DATA_KEY_UPDATE, update } from '@/data/Content/_Project';
import { useSettings } from '@/data/Settings';
import { useUser } from '@/data/User';
import { getActiveOrganizationId } from '@/data/utils/getActiveOrganizationId';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';

import useSWR from 'swr';
export type ProjectType = {
	projectId?: string;
};
export type ProjectContextType = {
	currentProjectId: string | null;
	name: string | null;
	deliveryInfo: { name: string; address: string; zipCode: string; city: string } | null;
	hasProjects: boolean;
	mutateCurrentProject: () => void;
};

export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);

export const ProjectProvider: FC<PropsWithChildren> = ({ children }) => {
	const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
	const [name, setName] = useState<string | null>(null);
	const [deliveryInfo, setDeliveryInfo] = useState<{
		name: string;
		address: string;
		zipCode: string;
		city: string;
	} | null>(null);
	const [hasProjects, setHasProjects] = useState<boolean>(false);

	const router = useNextRouter();
	const { settings } = useSettings();
	const { storeId } = getClientSideCommon(settings, router);
	const { user } = useUser();

	const { mutate } = useSWR(
		storeId ? [{ storeId }, DATA_KEY_UPDATE, getActiveOrganizationId] : null,
		async ([{ storeId }]) =>
			update(true)(storeId, '0').then((data) => {
				setCurrentProjectId(() => (data?.projectId === undefined ? null : data.projectId));
				setName(() => (data?.name === undefined ? null : data.name));
				setDeliveryInfo(() => (data?.deliveryInfo === undefined ? null : data.deliveryInfo));
				setHasProjects(() => (data?.hasProjects === undefined ? false : data.hasProjects));
				return data;
			}),
		{ revalidateOnFocus: false }
	);

	useEffect(() => {
		mutate();
	}, [user?.isLoggedIn]);

	return (
		<ProjectContext.Provider
			value={{ currentProjectId, name, deliveryInfo, hasProjects, mutateCurrentProject: mutate }}
		>
			{children}
		</ProjectContext.Provider>
	);
};

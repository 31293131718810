import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Invoice<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags invoice
	 * @name InvoiceHistory
	 * @summary Gets invoices for a customer.
	 * @request GET:/store/{storeId}/fca/invoice/erp/history
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	invoiceHistory = (
		storeId: string,
		query?: {
			/** @example 2024-01-01 */
			dateFrom?: string;
			/** @example 2024-01-01 */
			invoiceDateTo?: string;
			/**
			 * payed
			 * @example true
			 */
			payed?: boolean | null;
			/**
			 * orderId
			 * @example 1233
			 */
			orderId?: number;
			/**
			 * invoiceNumber
			 * @example 1233
			 */
			invoiceNumber?: number;
			/**
			 * page size
			 * @example 5
			 */
			pageSize?: number;
			/**
			 * requested page
			 * @example 1, 2
			 */
			pageNumber?: number;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('invoiceHistory'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'invoiceHistory',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/fca/invoice/erp/history`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags invoice
	 * @name InvoiceDetail
	 * @summary Gets an invoice for a customer.
	 * @request GET:/store/{storeId}/fca/invoice/erp/detail
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	invoiceDetail = (
		storeId: string,
		query?: {
			/**
			 * invoiceNumber
			 * @example 1233
			 */
			invoiceNumber?: number;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('invoiceDetail'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'invoiceDetail',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/fca/invoice/erp/detail`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}

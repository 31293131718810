import { ComCustomerCommerceRestOrderHandlerOrderResourceHandlerFetchOrders } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class ErpOrderHistory<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags ERPOrderHistory
	 * @name GetErpOrderHistory
	 * @summary Fetch erp order history.
	 * @request GET:/store/{storeId}/fca/order/erp/history
	 * @secure
	 * @response `200` `ComCustomerCommerceRestOrderHandlerOrderResourceHandlerFetchOrders` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	getErpOrderHistory = (
		storeId: string,
		query?: {
			/**
			 * The page size.
			 * @example 10, 20
			 */
			pageSize?: number;
			/**
			 * The page number.
			 * @example 1, 2
			 */
			pageNumber?: number;
			/**
			 * The earliest allowed date in the result.
			 * @example 2024-01-01
			 */
			dateFrom?: string;
			/**
			 * The latest allowed date in the result.
			 * @example 2024-02-02
			 */
			dateTo?: string;
			/**
			 * Order ID to find.
			 * @example 1750713
			 */
			orderId?: string;
			/**
			 * Find orders with the goodsMark.
			 * @example Eric's building materials
			 */
			goodsMark?: string;
			/**
			 * Find orders with the customerReference.
			 * @example Eric Carlson
			 */
			customerReference?: string;
			/**
			 * Find orders based on a range of states in the erp-system. First number(13) 13-70
			 * @example 13
			 */
			statusFrom?: number;
			/**
			 * Find orders based on a range of states in the erp-system. Last number(70) 13-70
			 * @example 70
			 */
			statusTo?: number;
			/**
			 * Find orders based on the state 'invoiced'(70) in the erp-system.
			 * @example true
			 */
			invoiced?: boolean;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('getErpOrderHistory'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'getErpOrderHistory',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComCustomerCommerceRestOrderHandlerOrderResourceHandlerFetchOrders,
			void
		>({
			path: `/store/${storeId}/fca/order/erp/history`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}

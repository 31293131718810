import { CustomGetPriceResult } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Price<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags price
	 * @name PriceByCatentryId
	 * @summary Gets price for a catentryId.
	 * @request GET:/store/{storeId}/price/byCatentryId/{catentryId}
	 * @secure
	 * @response `200` `CustomGetPriceResult` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	priceByCatentryId = (storeId: string, catentryId?: string, params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('priceByCatentryId'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: null ?? {},
				methodName: 'priceByCatentryId',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<CustomGetPriceResult, void>({
			path: `/store/${storeId}/price/byCatentryId/${catentryId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}
